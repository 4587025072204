import { navigate } from "gatsby";
import AuthService from "@okta/okta-react/dist/AuthService";
import { withPrefix } from "gatsby";

export default new AuthService({
  issuer: process.env.AUTH_ISSUER,
  clientId: process.env.AUTH_CLIENT_ID,
  redirectUri: window.location.origin + withPrefix("/implicit/callback"),
  onAuthRequired: () => navigate("/login")
});
